import { render, staticRenderFns } from "./itemsList.vue?vue&type=template&id=9fa26cdc&scoped=true&"
import script from "./itemsList.vue?vue&type=script&lang=js&"
export * from "./itemsList.vue?vue&type=script&lang=js&"
import style0 from "./itemsList.vue?vue&type=style&index=0&id=9fa26cdc&lang=scss&scoped=true&"
import style1 from "./itemsList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fa26cdc",
  null
  
)

export default component.exports