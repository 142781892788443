import {
  ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userPreferences from '@/store/userPreferences'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useList(abilityRequired, appStoreModuleName) {
  // Use toast
  const toast = useToast()

  const userPrefs = userPreferences()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'clientname', label: 'Client Name', sortable: true },
    { key: 'sitename', label: 'Site Name', sortable: true },
    { key: 'patrolname', label: 'Patrol Name', sortable: true },
    { key: 'patrol_in_order', label: 'Sequenced', sortable: false },
  ]
  const perPage = ref(10)
  if (userPrefs.getUserPref('patrolsPerPage') !== null) {
    // get the current value for per page
    perPage.value = userPrefs.getUserPref('patrolsPerPage')
  }
  const clientFilter = ref(null)
  if (userPrefs.getUserPref('patrolsClientFilter') !== null) {
    // get the current value for per page
    clientFilter.value = userPrefs.getUserPref('patrolsClientFilter')
  }
  const siteFilter = ref(null)
  if (userPrefs.getUserPref('patrolsSiteFilter') !== null) {
    // get the current value for per page
    siteFilter.value = userPrefs.getUserPref('patrolsSiteFilter')
  }
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('clientname')
  const isBusy = ref(false)
  const isSortDirDesc = ref(false)
  const theList = ref(null)
  const theListComplete = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    console.log('refetchData')
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, clientFilter, siteFilter], () => {
    const tmp = {
      patrolsPerPage: perPage.value,
      patrolsClientFilter: clientFilter.value,
      patrolsSiteFilter: siteFilter.value,
    }
    userPrefs.saveUserPrefs(tmp)
    refetchData()
  })

  const fetchListItems = (ctx, callback) => {
    console.log('fetchListItems')
    console.log(ctx)
    if (ability.can('read', abilityRequired)) {
      console.log('PASSED')
      const qvars = {
        forceRefresh: true,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        clientname: clientFilter.value,
        sitename: siteFilter.value,
      }
      console.log(qvars)
      store
        .dispatch(`${appStoreModuleName}/fetchTemplates`, qvars)
        .then(response => {
          const { theItems } = response.data
          console.log(callback)
          callback(theItems)
          theListComplete.value = theItems
          totalItems.value = response.data.totalItems
        })
        .catch(error => {
          console.log(error) // No current user
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  // Get the list of clients
  const qvars = {}
  qvars.status = 'active'
  qvars.q = null
  qvars.service = null

  // *===============================================---*
  // *--------- UI -------------------------------------*
  // *===============================================---*
  const resolveFlaggedVariant = status => {
    if (status === false) return 'success'
    if (status === true) return 'warning'
    return 'warning'
  }

  return {
    fetchListItems,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    theList,
    theListComplete,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refListTable,
    refetchData,
    clientFilter,
    siteFilter,

    resolveFlaggedVariant,
  }
}
